import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getPedidos, updateOrderStatus } from "../../services/ordersService";
import { OrderT } from "../../services/types";
import { formatDate } from "../../utils/data";
import { formatOrdersToCsv } from "../../utils/orders";
import { formatPrice, getDiscount } from "../../utils/price";
import Pagination from "../../components/Pagination";

const csvHeader = [
  { label: "Codigo do pedido", key: "codpedido" },
  { label: "Data pedido", key: "datapedido" },
  { label: "Método pag.", key: "pagamento" },
  { label: "Status do pedido", key: "status" },
  { label: "Nome Cliente", key: "nome" },
  { label: "CPF Cliente", key: "cpf" },
  { label: "Endereço", key: "endereco" },
  { label: "Complemento", key: "complemento" },
  { label: "Bairro", key: "bairro" },
  { label: "CEP", key: "cep" },
  { label: "Produtos", key: "produtos" },
  { label: "Valor frete", key: "frete" },
  { label: "Cupom", key: "cupom" },
  { label: "Descontos", key: "descontos" },
  { label: "Valor produtos", key: "total_produtos" },
  { label: "Valor pedido", key: "total" },
];

const itemPerPage = [10, 20, 50, 100];

const STATUS_TO_SHOW_BUTTON_UPDATE_PAYMENT = [
  "Aguardando aprovação da plataforma",
];

export default function OrderList() {
  const navigator = useNavigate();

  const [orders, setOrders] = useState<OrderT[]>([]);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState<string>("");

  const [ref, setRef] = useState("");
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limitQuantity, setLimitQuantity] = useState<number>(itemPerPage[0]);
  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [pagesTotal, setPagesTotal] = useState<number>(0);

  const getOrders = async ({
    limit,
    page,
    ref,
    minDate,
    maxDate,
    saveOnContext = false,
  }: {
    limit: number;
    page: number;
    ref?: string;
    minDate: string;
    maxDate: string;
    saveOnContext?: boolean;
  }) => {
    setLoading(true);

    const {
      pedidos: _orders,
      pages,
      total,
    } = await getPedidos({ limit, page, ref, minDate, maxDate, status });

    if (saveOnContext) setOrders(_orders);

    setPagesTotal(pages);
    setResultsTotal(total);

    setLoading(false);

    return Promise.resolve(_orders);
  };

  const [csvOrders, setCsvOrders] = useState([]);

  async function fetchOrders() {
    await getOrders({
      limit: limitQuantity,
      page: pageNumber,
      maxDate,
      minDate,
      ref,
      saveOnContext: true,
    });

    // get all orders to CSV
    const _orders = await getOrders({
      limit: limitQuantity,
      page: pageNumber,
      maxDate,
      minDate,
      ref,
    });

    setCsvOrders(_orders);
  }

  useEffect(() => {
    fetchOrders();
  }, [limitQuantity, pageNumber]);

  const handleUpdateStatusPayment = async (order: OrderT) => {
    setLoading((prevState) => !prevState);
    await updateOrderStatus(order.codPedido);
    fetchOrders();
  };

  return (
    <section className="wrapper">
      <FiltersContainer>
        <SearchInput value={ref} onChange={(e) => setRef(e.target.value)} />
      </FiltersContainer>
      <LeftFilters>
        <DateContainer>
          <p>Data mínima</p>

          <DatePicker
            type="date"
            value={minDate}
            onChange={(e) => setminDate(e.target.value)}
          />
        </DateContainer>

        <DateContainer>
          <p>Data máxima</p>

          <DatePicker
            type="date"
            value={maxDate}
            onChange={(e) => setmaxDate(e.target.value)}
          />
        </DateContainer>
        <DateContainer>
          <p>Status</p>
          <select
            name="status"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value=""></option>
            <option value="Aguardando aprovação da plataforma">
              Aguardando aprovação da plataforma
            </option>
            <option value="Aguardando confirmação de entrega">
              Aguardando confirmação de entrega
            </option>
            <option value="Aguardando embarque">Aguardando embarque</option>
            <option value="Aguardando faturamento">
              Aguardando faturamento
            </option>
            <option value="Aguardando impressão relatório separação">
              Aguardando impressão relatório separação
            </option>
            <option value="Aguardando pagamento">Aguardando pagamento</option>
            <option value="Cancelado">Cancelado</option>
          </select>
        </DateContainer>

        <ApplyButton onClick={() => fetchOrders()}>Pesquisar</ApplyButton>
      </LeftFilters>

      {loading ? (
        <div>carregando...</div>
      ) : orders?.length > 0 ? (
        <div>
          {!loading && csvOrders.length > 0 && (
            <ContainerActions>
              <DownloadOrdersButton
                data={formatOrdersToCsv(csvOrders)}
                headers={csvHeader}
                filename={"pedidos_app.csv"}
              >
                Download pedidos
              </DownloadOrdersButton>
              <Pagination
                totalPages={pagesTotal}
                currentPage={pageNumber}
                setCurrentPage={setPageNumber}
                itemsPerPageOptions={itemPerPage}
                onItemsPerPageChange={setLimitQuantity}
                itemsPerPage={limitQuantity}
                resultsTotal={resultsTotal}
              />
            </ContainerActions>
          )}

          <Table>
            <thead>
              <tr>
                <HeaderCell>Código do Pedido</HeaderCell>
                <HeaderCell>Nome Cliente</HeaderCell>
                <HeaderCell>Data Criação</HeaderCell>
                <HeaderCell>Total</HeaderCell>
                <HeaderCell>Quantidade</HeaderCell>
                <HeaderCell>Produtos</HeaderCell>
                <HeaderCell>Status</HeaderCell>
                <HeaderCell>Atualizar status Pagamento</HeaderCell>
                <HeaderCell>Última Alteração</HeaderCell>
              </tr>
            </thead>
            <tbody>
              {orders.map((order: OrderT) => {
                const { frete, produtos } = order.valores;

                const discounts = getDiscount(order.cupom, produtos, order);

                const total =
                  Number(produtos) + Number(frete) - Number(discounts);

                const productsQtd = order.produtos.reduce((acc, curr) => {
                  return acc + curr.quantidade;
                }, 0);

                return (
                  <Row style={{ cursor: "pointer" }} key={order.codPedido}>
                    <Cell>
                      <StyledLink
                        href={`/order/${order.codPedido}`}
                        target="_self"
                      >
                        {order.codPedido}
                      </StyledLink>
                    </Cell>
                    <Cell>{order.cliente.nome}</Cell>
                    <Cell>{formatDate(order.createdAt)}</Cell>
                    <Cell>{formatPrice(total)}</Cell>
                    <Cell>{productsQtd}</Cell>
                    <CellProduct>
                      <DivTeste>
                        {order?.produtos?.map((product) => (
                          <>
                            <span>{product.quantidade}x -</span>
                            <p>{product.nome}</p>
                          </>
                        ))}
                      </DivTeste>
                    </CellProduct>
                    <Cell>{order.status}</Cell>
                    <Cell>
                      {(STATUS_TO_SHOW_BUTTON_UPDATE_PAYMENT.includes(
                        order.status
                      ) ||
                        !order.status) && (
                        <UpdatePayment
                          onClick={() => handleUpdateStatusPayment(order)}
                        >
                          Atualizar
                        </UpdatePayment>
                      )}
                    </Cell>
                    <Cell>{formatDate(order.updatedAt)}</Cell>
                  </Row>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <div>nenhum produto encontrado</div>
      )}
    </section>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const CellProduct = styled.td`
  border-bottom: 1px solid #d6d6d6;
  font-size: 14px;
  font-weight: 400;

  overflow-y: scroll;
`;

const DivTeste = styled.div`
  max-height: 100px;

  span {
    font-weight: 700;
  }

  p {
    margin-bottom: 10px;
  }
`;

const StyledLink = styled.a`
  color: blue;
  text-decoration: underline;
  &:visited {
    color: blue;
    text-decoration: underline;
  }
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1em;
  padding: 0.5rem;
`;

const DateContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DatePicker = styled.input.attrs({
  type: "date",
})``;

const SearchInput = styled.input.attrs({
  placeholder: "Digite um código de pedido, email ou nome do cliente",
})`
  flex: 1;
  height: 2.5rem;
  padding: 0.5rem;
`;

const ApplyButton = styled.button`
  width: 12.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #7bc55e;
  }
`;

const UpdatePayment = styled.button`
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #7bc55e;
  }
`;

const LeftFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const DownloadOrdersButton = styled(CSVLink)`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;

const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
