import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addProductToCollection,
  listProductsByClusterId,
  removeClusterAndBanner,
  removeProductFromCluster,
} from "../../services/collectionService";
import { ClusterAndCountT, IProductByCluster } from "../../services/types";
import styled from "styled-components";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import {
  putCollectionsProducts,
  TItem,
} from "../../services/collectionsProductsService";
import ProductSelectorCluster from "../../components/ClusterManager/ProductSelectorCluster";

type CombinedProductType = {
  imagem: string;
  nome: string;
  estoque: string;
  pro_in_codigo: number;
  ativo: boolean;
  ordem: string;
  fil_in_codigo: number;
  clu_in_codigo: number;
};

export default function ClusterDetails() {
  const [loading, setLoading] = useState(false);
  const [addProduct, setAddProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [itemList, setItemList] = useState<CombinedProductType[]>([]);

  const location = useLocation();
  const navigator = useNavigate();

  const clusterState = location.state as ClusterAndCountT;

  async function confirmModal(fn: (...args: any) => Promise<void>) {
    const result = await Swal.fire({
      title: "Tem certeza que deseja excluir?",
      text: "Esta ação não pode ser desfeita",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, tenho certeza!",
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      return fn();
    }
  }

  const productsMapper = async (
    response: IProductByCluster[]
  ): Promise<CombinedProductType[]> => {
    const productsArray = [];
    for (let i = 0; i < response.length; i++) {
      const element = response[i];

      const url = await getImageDownloadUrlFromFireBase(
        element.imagens[0].pim_st_imagesrc
      );

      productsArray.push({
        imagem: url,
        nome: element.pro_st_descricao || element.pro_st_nomesite,
        estoque: element.estoque[0].est_de_saldo,
        pro_in_codigo: element.pro_in_codigo,
        ativo: element.produtocolecao[0].clp_bo_ativo,
        ordem: element.produtocolecao[0].clp_in_ordem,
        fil_in_codigo: element.estoque[0].fil_in_codigo,
        clu_in_codigo: element.produtocolecao[0].clu_in_codigo,
      });
    }

    productsArray.sort((a, b) => Number(a.ordem) - Number(b.ordem));

    return productsArray;
  };

  const getProductsByCluster = async (clusterId: number) => {
    setLoading(true);

    const response: IProductByCluster[] = await listProductsByClusterId(
      clusterId
    );
    const newProducts = await productsMapper(response);
    setItemList(newProducts);
    setLoading(false);
  };

  const deleteCluster = async (clusterId: number) => {
    setLoading(true);
    await removeClusterAndBanner(clusterId);
    navigator(`/clustersList`);
    setLoading(false);
  };

  const deleteItem = async (clusterId: number, productId: number) => {
    setLoading(true);
    await removeProductFromCluster(clusterId, productId);
    await getProductsByCluster(clusterState.clu_in_codigo);
    setLoading(false);
  };

  const addProductToCluster = async (productId: string) => {
    setLoading(true);
    await addProductToCollection(clusterState.clu_in_codigo, productId, 0);
    await getProductsByCluster(clusterState.clu_in_codigo);
    setSelectedProduct("");
    setAddProduct(false);
    setLoading(false);
  };

  useEffect(() => {
    getProductsByCluster(clusterState.clu_in_codigo);
  }, []);

  if (loading) {
    return <div>carregando...</div>;
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    if (!Array.isArray(list)) {
      console.error("List não é um array!", { list });
      return list;
    }

    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const updatedResult = result.map((item, index) => ({
      ...item,
      ordem: index + 1,
    }));

    return updatedResult;
  };

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      itemList,
      result.source.index,
      result.destination.index
    );

    setItemList([...items]);
  }

  const handleUpdateColecoesProdutos = () => {
    const colecoesProdutos: TItem[] = itemList.map((cp) => ({
      clu_in_codigo: cp.clu_in_codigo,
      pro_in_codigo: cp.pro_in_codigo,
      clp_in_ordem: cp.ordem,
      clp_bo_ativo: cp.ativo,
    }));
    putCollectionsProducts(colecoesProdutos);
    navigator(`/clustersList`);
  };

  const handleSelectChange = (id: number, e: string) => {
    const newValues = itemList.map((i) => {
      if (i.pro_in_codigo === id) {
        return { ...i, ativo: e === "1" ? true : false };
      }
      return i;
    });

    setItemList(newValues);
  };

  return (
    <section className="wrapper">
      {itemList?.length > 0 ? (
        <>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          ></link>
          <TitleRow>
            <div>
              <h1>Coleção: {clusterState.clu_st_nome}</h1>
            </div>
            <div>
              <Button
                style={{ marginRight: "1.5rem", backgroundColor: "red" }}
                variant={"light"}
                onClick={() =>
                  confirmModal(() => deleteCluster(clusterState.clu_in_codigo))
                }
              >
                Excluir coleção
              </Button>
              <Button variant={"dark"} onClick={() => setAddProduct(true)}>
                Adicionar produto
              </Button>
            </div>
          </TitleRow>

          <InfoText>
            Para alterar a ordem da coleção basta arrastar as linhas.
          </InfoText>
          <InfoText>
            <strong>Para encontrar e adicionar o produto, ele precisa:</strong>
          </InfoText>
          <strong>- Estar marcado como ATIVO</strong>
          <br />
          <strong>- Estar marcado como EXIBIR NO SITE</strong>
          <br />
          <strong>- Produto precisa ter ESTOQUE</strong>

          {addProduct && (
            <section className="wrapper">
              {addProduct && (
                <ProductSelectorCluster
                  initialProd={selectedProduct}
                  setProd={setSelectedProduct}
                  setFormIsValid={setIsFormValid}
                />
              )}
              <AddProductButtonContainer>
                <Button
                  variant={"light"}
                  onClick={() =>
                    isFormValid && addProductToCluster(selectedProduct)
                  }
                  disabled={!isFormValid}
                >
                  Confirmar adição do produto
                </Button>
              </AddProductButtonContainer>
            </section>
          )}
          <Table>
            <HeaderCell>Foto</HeaderCell>
            <HeaderCell>Nome</HeaderCell>
            <HeaderCell>Estoque</HeaderCell>
            <HeaderCell>Ativo</HeaderCell>
            <HeaderCell>Ordem</HeaderCell>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, _snapshot) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {itemList.map(
                      (product: CombinedProductType, index: number) => (
                        <Draggable
                          key={product.pro_in_codigo.toString()}
                          draggableId={product.pro_in_codigo.toString()}
                          index={Number(product.ordem) - 1}
                        >
                          {(provided, snapshot) => (
                            <Row
                              style={{ backgroundColor: "blue", width: "100%" }}
                              key={product.pro_in_codigo}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Cell>
                                <img
                                  style={{ width: "40px", height: "40px" }}
                                  src={product.imagem}
                                  alt={product.nome}
                                />
                              </Cell>
                              <Cell>{product.nome}</Cell>
                              <Cell>{Number(product.estoque) || "0"}</Cell>
                              <Cell>
                                <select
                                  value={product.ativo ? 1 : 0}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLSelectElement>
                                  ) =>
                                    handleSelectChange(
                                      product.pro_in_codigo,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="1">Sim</option>
                                  <option value="0">Não</option>
                                </select>
                              </Cell>
                              <Cell>{product.ordem || "-"}</Cell>
                              <Cell>
                                {" "}
                                <Button
                                  onClick={() =>
                                    confirmModal(() =>
                                      deleteItem(
                                        clusterState.clu_in_codigo,
                                        product.pro_in_codigo
                                      )
                                    )
                                  }
                                  variant={"dark"}
                                  style={{ backgroundColor: "white" }}
                                >
                                  <i
                                    className="fa fa-trash-o"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </Button>{" "}
                              </Cell>
                            </Row>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
          <FooterRow>
            <Button
              onClick={() => handleUpdateColecoesProdutos()}
              variant={"dark"}
            >
              Salvar Alterações
            </Button>
          </FooterRow>
        </>
      ) : (
        <div>
          <TitleRow>
            <div>
              <h1>Coleção: {clusterState.clu_st_nome}</h1>
            </div>
            <div>
              <Button
                style={{ marginRight: "1.5rem", backgroundColor: "red" }}
                variant={"light"}
                onClick={() =>
                  confirmModal(() => deleteCluster(clusterState.clu_in_codigo))
                }
              >
                Excluir coleção
              </Button>
              <Button variant={"dark"} onClick={() => setAddProduct(true)}>
                Adicionar produto
              </Button>
            </div>
          </TitleRow>

          {addProduct && (
            <section className="wrapper">
              {addProduct && (
                <ProductSelectorCluster
                  initialProd={selectedProduct}
                  setProd={setSelectedProduct}
                  setFormIsValid={setIsFormValid}
                />
              )}
              <AddProductButtonContainer>
                <Button
                  variant={"light"}
                  onClick={() =>
                    isFormValid && addProductToCluster(selectedProduct)
                  }
                  disabled={!isFormValid}
                >
                  Confirmar adição do produto
                </Button>
              </AddProductButtonContainer>
            </section>
          )}

          <InfoText>Nenhum produto encontrado</InfoText>
          <FooterRow>
            <Button onClick={() => navigator(`/clustersList`)} variant={"dark"}>
              Voltar
            </Button>
          </FooterRow>
        </div>
      )}
    </section>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterRow = styled.tr`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const InfoText = styled.p`
  color: red;
  margin: 10px 0 20px 0;
  font-size: 14px;
`;

const AddProductButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;
`;
