import { useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import { getProducts } from "../services/productsService";
import { toast } from "react-toastify";
import { formatedProduct } from "../utils/formatProductsFromApiToListItem";

export default function ProductSearch({
  setProduct,
}: {
  setProduct: (product: formatedProduct) => void;
}) {
  const [productSearch, setProductSearch] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleProductSearch = async () => {
    if (!productSearch || productSearch === "") {
      toast.warn(`Digite um SKU para buscar`);
      return;
    }

    setLoading(true);
    try {
      const product = await getProducts({ sku: productSearch });
      setProduct(product);
      setProductSearch("");
    } catch (error: any) {
      toast.error(`Erro ao buscar produto: ${error?.message}`);
    }
    setLoading(false);
  };

  return (
    <Container>
      <p>Adicione um produto por SKU</p>
      <InputContainer>
        <Input
          type="text"
          name="categorySearch"
          onChange={(e) => setProductSearch(e.currentTarget.value)}
          maxLength={60}
          autoComplete="off"
          value={productSearch}
        />
        <Button
          variant="light"
          onClick={handleProductSearch}
          disabled={loading}
        >
          {`${loading ? "Carregando" : "Adicionar"}`}
        </Button>
      </InputContainer>
    </Container>
  );
}

const Input = styled.input`
  width: 100%;
  margin: 0.5rem 0;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  button {
    margin: 0.5rem 0 0.5rem 2rem;
    max-height: 36px;
    border-radius: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;
`;
