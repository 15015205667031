import styled from "styled-components";
import {
  findCategoryProductsOrder,
  getCategoriesWithDetails,
  saveCategoryProductsOrder,
} from "../../services/categoriesService";
import { useCallback, useEffect, useState } from "react";
import { FormattedCategory } from "../../services/types";
import { toast } from "react-toastify";
import CategoryPicker, {
  FlattenedCategory,
} from "../../components/CategoryPicker";
import ProductsOrderManager from "../../components/ProductsOrderManager";
import ProductSearch from "../../components/ProductSearch";
import { formatedProduct } from "../../utils/formatProductsFromApiToListItem";
import Button from "../../components/Button";

export default function CategoriesProductsOrder() {
  const [chosenCategory, setChosenCategory] =
    useState<FlattenedCategory | null>(null);
  const [categories, setCategories] = useState<FormattedCategory[]>([]);
  const [categorySearch, setCategorySearch] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [productList, setProductList] = useState<formatedProduct[]>([]);

  const handleGetCategories = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCategoriesWithDetails();
      if (response.length) {
        setCategories(response);
      } else {
        setCategories([]);
      }
    } catch (err) {
      toast.error(`Erro ao buscar categorias \n", ${error}`);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleAddProductToList = (product?: formatedProduct) => {
    if (product?.id)
      setProductList([
        product,
        ...productList.filter((p) => p.id !== product.id),
      ]);
  };

  const handleSaveOrder = async () => {
    if (!chosenCategory?.id) {
      toast.warn(
        "Escolha uma categoria e adicione produtos na lista para salvar"
      );
      return;
    }

    setLoading(true);
    try {
      await saveCategoryProductsOrder({
        categoryId: chosenCategory.id,
        products: productList,
      });
      toast.success(`Ordenação salva!`);
    } catch (error: any) {
      toast.error(`Erro ao salvar ordenação: ${error?.message}`);
    }
    setLoading(false);
  };

  const handleGetOrderedProducts = async () => {
    if (!chosenCategory?.id) return;

    setLoading(true);
    try {
      const products = await findCategoryProductsOrder({
        categoryId: chosenCategory.id,
      });
      if (products) setProductList(products);
    } catch (error: any) {
      toast.error(`Erro ao buscar ordenação: ${error?.message}`);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetCategories();
  }, [handleGetCategories]);

  useEffect(() => {
    setProductList([]);
    handleGetOrderedProducts();
  }, [chosenCategory]);

  const handleCategorySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCategorySearch(e.target.value);
  };

  return (
    <section className="wrapper">
      <Title>Ordenação de Produtos</Title>
      <Details>
        <p>
          1. <b>Selecione uma categoria</b>: Escolha a categoria na qual deseja
          gerenciar os produtos.
        </p>
        <p>
          2. <b>Adicione os produtos desejados</b>: Inclua os produtos que devem
          ter prioridade de exibição na categoria.
        </p>
        <p>
          3. <b>Organize a ordem dos produtos</b>: Arraste e solte os produtos
          para definir a ordem de exibição na categoria.
        </p>
        <p>
          4. <b>Salve as alterações</b>: Clique em <b>Salvar</b> para confirmar
          as alterações realizadas.
        </p>
        <br></br>
        <p>
          * Produtos que não pertencem à categoria selecionada{" "}
          <b>não serão exibidos na listagem dessa categoria</b>.
        </p>
        <p>
          * As alterações podem levar <b>até 30 minutos</b> para serem
          refletidas no site
        </p>
        <p>
          * Os produtos adicionados nesta seção serão exibidos <b>primeiro</b>{" "}
          na listagem da categoria selecionada.
        </p>
      </Details>
      <Actions>
        <p>
          <strong>Categoria selecionada: </strong>
          <span>
            {chosenCategory?.id
              ? `${chosenCategory?.description} (${chosenCategory?.id})`
              : "Nenhuma"}
          </span>
        </p>
        <Button variant="light" onClick={handleSaveOrder}>
          Salvar
        </Button>
      </Actions>
      {loading ? (
        <Title>Carregando...</Title>
      ) : (
        <Content>
          <Main>
            <InputContainer>
              <p>Busque o nome de uma categoria, e selecione abaixo</p>
              <Input
                type="text"
                name="categorySearch"
                onChange={handleCategorySearchChange}
                maxLength={60}
                autoComplete="off"
                value={categorySearch}
              />
            </InputContainer>
            <CategoryPicker
              categories={categories}
              setChosenCategory={setChosenCategory}
              selectedCategory={chosenCategory}
              filter={categorySearch}
            />
          </Main>
          <Main>
            <ProductSearch setProduct={handleAddProductToList} />
            <ProductsOrderManager
              setProducts={setProductList}
              products={productList}
            />
          </Main>
        </Content>
      )}
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const Details = styled.section`
  margin-left: 1rem;
`;

const Actions = styled.section`
  margin-left: 1rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;

  button {
    margin: 0.5rem 0 0.5rem 2rem;
    max-height: 36px;
    border-radius: 0;
  }
`;

const Input = styled.input`
  width: 31.25rem;
  margin: 0.52rem 0 0.5rem;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 2rem;
`;

const Main = styled.section`
  margin-left: 1rem;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  section {
    width: 48%;
  }
`;
